<template>
  <div />
</template>

<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'
import usesLeadPost from 'chimera/all/mixins/usesLeadPost'

export const field = 'submit'
export default {
  name: 'LeadSubmitFormPart',

  extends: AbstractFormField,

  mixins: [
    usesLeadPost
  ],

  props: {
    field: {
      type: String,
      default: field
    }
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      value: undefined
    }
  },

  methods: {
    /**
     * @param {*} value
     */
    afterOnValidLeadPost (value) {
      this.value = true
      this.$emit('leadPostValid', value)
    },

    /**
     * @param {object} errorData
     */
    afterOnInvalidLeadPost (errorData) {
      this.value = false
      this.$emit('leadPostInvalid', errorData)
    },

    /**
     * @param {Error} error
     */
    afterOnErrorLeadPost (error) {
      this.$emit('leadPostError', error)
    }
  }
}
</script>
